import { useEffect, useState } from "react";
import {
  getBuybackReviews,
  updateBuybackReview,
  getBuybackReviewMedias,
  getBuybackItems,
} from "../api/AdminApi";
import { BuybackReviewTable } from "../types/types";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface ReviewBuybackItem {
  productName: string;
  id: number;
  orderId: string;
  reviewId: number;
  csDecision: "PENDING" | "REJECTED" | "ACCEPTED" | "CONDITIONALLY_ACCEPTED";
  buybackPrice: string;
  customerAddress: string;
  images?: string[];
  customerComments: string;
  storeDrop: boolean;
  csReviewDate: string;
  alternatePhoneNo: string;
  customerPhone: string;
  paymentMethod: string;
}

export function ReviewBuyback() {
  const [data, setData] = useState<ReviewBuybackItem[]>([]);
  const [comments, setComments] = useState<{ [key: number]: string }>({});
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [commentWarnings, setCommentWarnings] = useState<{
    [key: number]: string;
  }>({});

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const [reviewsResponse, itemsResponse] = await Promise.all([
          getBuybackReviews(),
          getBuybackItems(),
        ]);

        const itemsMap = new Map(
          itemsResponse.data.map((item) => [
            item.id,
            {
              alternatePhoneNo: item.alternate_phone_no,
              customerPhone: item.customer_phone,
              paymentMethod: item.payment_method,
            },
          ])
        );

        const reviews: ReviewBuybackItem[] = await Promise.all(
          reviewsResponse.data.map(async (item: BuybackReviewTable) => {
            const itemDetails = itemsMap.get(item.item_id);
            let images: string[] = [];
            try {
              const imageResponse = await getBuybackReviewMedias(
                item.item_id,
                item.id
              );
              if (imageResponse.data) {
                images = imageResponse.data.map(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (media: { media_url: any }) => media.media_url
                );
              }
            } catch (error) {
              console.error("Error fetching images:", error);
            }

            return {
              productName: item.product_name,
              buybackPrice: item.buyback_price,
              customerAddress: item.customer_address,
              csDecision: item.cs_review_status || "PENDING",
              id: item.item_id,
              orderId: `0000${item.order_id}`,
              reviewId: item.id,
              images: images,
              customerComments: item.customer_comments || "",
              storeDrop: Boolean(item.store_drop),
              csReviewDate: new Date(item.cs_review_date).toLocaleDateString(),
              alternatePhoneNo: itemDetails?.alternatePhoneNo || "",
              customerPhone: itemDetails?.customerPhone || "",
              paymentMethod: itemDetails?.paymentMethod || "",
            };
          })
        );
        setData(reviews);
      } catch (error) {
        console.error("Error fetching buyback reviews:", error);
      }
    };

    fetchReviews();
  }, []);

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseImage = () => {
    setSelectedImage(null);
  };

  const handleUpdateReview = async (
    productId: number,
    reviewId: number,
    status: "ACCEPTED" | "CONDITIONALLY_ACCEPTED",
    productName: string
  ) => {
    try {
      await updateBuybackReview(productId, reviewId, {
        csReviewStatus: status,
      });

      setData((prevData) =>
        prevData.map((item) =>
          item.id === productId ? { ...item, csDecision: status } : item
        )
      );

      alert(`Product ${productName} has been marked as ${status}`);
    } catch {
      alert("Failed to update review.");
    }
  };

  const handleReject = async (productId: number, reviewId: number) => {
    const comment = comments[productId];
    if (!comment?.trim()) {
      setCommentWarnings((prev) => ({
        ...prev,
        [productId]: "Please add comments before rejecting the product",
      }));
      return;
    }

    try {
      await updateBuybackReview(productId, reviewId, {
        csReviewStatus: "REJECTED",
        reviewComments: comments[productId],
      });

      setData((prevData) =>
        prevData.map((item) =>
          item.id === productId ? { ...item, csDecision: "REJECTED" } : item
        )
      );
      // Clear warning after successful action
      setCommentWarnings((prev) => ({ ...prev, [productId]: "" }));
    } catch {
      alert("Failed to reject review.");
    }
  };

  const handleConditionalAccept = async (
    productId: number,
    reviewId: number
  ) => {
    const comment = comments[productId];
    if (!comment?.trim()) {
      setCommentWarnings((prev) => ({
        ...prev,
        [productId]:
          "Please add comments before conditionally accepting the product",
      }));
      return;
    }

    try {
      await updateBuybackReview(productId, reviewId, {
        csReviewStatus: "CONDITIONALLY_ACCEPTED",
        reviewComments: comments[productId],
      });
      setData((prevData) =>
        prevData.map((item) =>
          item.id === productId
            ? { ...item, csDecision: "CONDITIONALLY_ACCEPTED" }
            : item
        )
      );
      // Clear warning after successful action
      setCommentWarnings((prev) => ({ ...prev, [productId]: "" }));
    } catch {
      alert("Failed to update review.");
    }
  };

  const handleCommentChange = (productId: number, value: string) => {
    setComments((prev) => ({ ...prev, [productId]: value }));
    // Clear warning when user starts typing
    if (value.trim()) {
      setCommentWarnings((prev) => ({ ...prev, [productId]: "" }));
    }
  };

  return (
    <div className="rounded-lg bg-white p-6 shadow-lg">
      <h2 className="mb-6 text-center text-2xl font-semibold text-[#f78a8a]">
        Review Buyback
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {data.map((item) => (
          <div
            key={item.id}
            className="bg-white rounded-lg shadow-md p-6 relative"
          >
            <div className="absolute top-2 left-2 text-xl px-2 py-1 rounded-full bg-gray-200">
              {item.orderId}
            </div>
            <div
              className={`absolute top-2 right-2 text-xs px-2 py-1 rounded ${
                item.csDecision === "ACCEPTED"
                  ? "bg-green-500 text-white"
                  : item.csDecision === "CONDITIONALLY_ACCEPTED"
                  ? "bg-blue-500 text-white"
                  : item.csDecision === "REJECTED"
                  ? "bg-red-500 text-white"
                  : "bg-gray-200 text-gray-700"
              }`}
            >
              {item.csDecision}
            </div>

            <div className="flex gap-2 mb-4 mt-7">
              {item.images && item.images.length > 0 ? (
                item.images.map((image, imgIndex) => (
                  <img
                    key={imgIndex}
                    src={image}
                    alt={`${item.productName} image ${imgIndex + 1}`}
                    className="w-20 h-20 object-cover rounded-md cursor-pointer"
                    onClick={() => handleImageClick(image)}
                  />
                ))
              ) : (
                <p>No images available</p>
              )}
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-6">
                <div>
                  <h3 className="text-x-sm text-gray-500">
                    PRODUCT NAME
                  </h3>
                  <p className="text-base font-bold text-md">
                    {item.productName}
                  </p>
                </div>

                <div>
                  <h3 className="text-x-sm text-gray-500">
                    BUYBACK PRICE
                  </h3>
                  <p className="text-base font-bold text-md">
                    AED {item.buybackPrice}
                  </p>
                </div>

                <div>
                  <h3 className="text-x-sm text-gray-500">
                    CUSTOMER ADDRESS
                  </h3>
                  <p className="text-base font-bold text-md">
                    {item.customerAddress}
                  </p>
                </div>

                <div>
                  <h3 className="text-x-sm text-gray-500">
                    CUSTOMER COMMENTS
                  </h3>
                  <p className="text-base font-bold text-md">
                    {item.customerComments}
                  </p>
                </div>
              </div>

              <div className="space-y-6">
                <div>
                  <h3 className="text-x-sm text-gray-500">
                    STORE DROP
                  </h3>
                  <p className="text-base font-bold text-md">
                    {item.storeDrop ? "Yes" : "No"}
                  </p>
                </div>

                <div>
                  <h3 className="text-x-sm text-gray-500">
                    DSIRED PICKUP DATE
                  </h3>
                  <p className="text-base font-bold text-md">
                    {item.csReviewDate}
                  </p>
                </div>

                <div>
                  <h3 className="text-x-sm text-gray-500">
                    PHONE NUMBER
                  </h3>
                  <p className="text-base font-bold text-md">
                    {item.customerPhone}, {item.alternatePhoneNo}
                  </p>
                </div>
                <div>
                  <h3 className="text-x-sm text-gray-500">
                    PAYMENT METHOD
                  </h3>
                  <p className="text-base font-bold text-md">
                    {item.paymentMethod}
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <textarea
                placeholder="Add comments here... (Required for Conditional Accept and Reject)"
                className={`w-full p-2 border rounded-lg bg-white text-left ${
                  commentWarnings[item.id]
                    ? "border-red-500"
                    : "border-gray-300"
                }`}
                value={comments[item.id] || ""}
                onChange={(e) => handleCommentChange(item.id, e.target.value)}
              />
              {commentWarnings[item.id] && (
                <p className="text-red-500 text-sm mt-1">
                  {commentWarnings[item.id]}
                </p>
              )}
            </div>

            <div className="flex gap-2 mt-4">
              <button
                className={`flex-1 px-4 py-2 rounded-full text-center ${
                  item.csDecision === "ACCEPTED"
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-green-500 text-white hover:bg-green-600"
                }`}
                onClick={() =>
                  handleUpdateReview(
                    item.id,
                    item.reviewId,
                    "ACCEPTED",
                    item.productName
                  )
                }
                disabled={item.csDecision === "ACCEPTED"}
              >
                ACCEPT
              </button>
              <button
                className={`flex-1 px-4 py-2 rounded-full text-center ${
                  item.csDecision === "CONDITIONALLY_ACCEPTED"
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-blue-500 text-white hover:bg-blue-600"
                }`}
                onClick={() => handleConditionalAccept(item.id, item.reviewId)}
                disabled={item.csDecision === "CONDITIONALLY_ACCEPTED"}
              >
                CONDITIONAL ACCEPT
              </button>
              <button
                className={`flex-1 px-4 py-2 rounded-full text-center ${
                  item.csDecision === "REJECTED"
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-red-500 text-white hover:bg-red-600"
                }`}
                onClick={() => handleReject(item.id, item.reviewId)}
                disabled={item.csDecision === "REJECTED"}
              >
                REJECT
              </button>
            </div>
          </div>
        ))}
      </div>

      {selectedImage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded-lg w-[36vw] h-[74vh] mx-auto relative">
            <button
              onClick={handleCloseImage}
              className="absolute -top-10 right-0 bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center hover:bg-red-600 z-50"
            >
              X
            </button>
            <TransformWrapper initialScale={1} centerOnInit={true}>
              {({ zoomIn, zoomOut, resetTransform, centerView }) => (
                <>
                  <div className="flex gap-2 mb-4 justify-center">
                    <button
                      onClick={() => zoomIn()}
                      className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                    >
                      Zoom In
                    </button>
                    <button
                      onClick={() => zoomOut()}
                      className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                    >
                      Zoom Out
                    </button>
                    <button
                      onClick={() => centerView()}
                      className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                    >
                      Center
                    </button>
                    <button
                      onClick={() => resetTransform()}
                      className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                    >
                      Reset
                    </button>
                  </div>
                  <TransformComponent>
                    <img
                      src={selectedImage}
                      alt="Zoomable"
                      className="object-contain rounded-lg w-[78vw] h-[65vh] mx-auto relative"
                    />
                  </TransformComponent>
                </>
              )}
            </TransformWrapper>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReviewBuyback;
