import { useEffect, useState } from "react";
import { getBuybackItems, getBuybackReviews, updateInventory } from "../api/AdminApi";

interface UpdateInventoryItem {
  id: number;
  orderId: string;
  productName: string;
  customerName: string;
  customerComments: string;
  csDecision: "PENDING" | "REJECTED" | "ACCEPTED" | "CONDITIONALLY_ACCEPTED";
  inventoryType: "USED_PRODUCT" | "RENTAL_PRODUCT";
  poid: string | null;
  poNumber: string | null;
  serialNo: string;
  productSKU: string;
  reviewComment: string;
  itemRepairComments: string;
  customerAddress: string | null;
  storeDrop: boolean;
  paymentMethod: string;
  customerPhone: string;
  reviewDate: string;
  alternatePhoneNo: string | null;
}

interface CommentPopupProps {
  customerComment: string;
  reviewComment: string;
  itemRepairComments: string;
  customerAddress: string | null;
  storeDrop: boolean;
  paymentMethod: string;
  customerPhone: string;
  reviewDate: string;
  alternatePhoneNo: string;
  onClose: () => void;
}

const CommentPopup: React.FC<CommentPopupProps> = ({ 
  customerComment, 
  reviewComment, 
  itemRepairComments,
  customerAddress,
  storeDrop,
  paymentMethod,
  customerPhone,
  reviewDate,
  alternatePhoneNo,
  onClose 
}) => {
  // Format reviewDate to 'YYYY-MM-DD'
  const formattedReviewDate = reviewDate
    ? new Date(reviewDate).toISOString().split("T")[0]
    : "Not specified";

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-2xl w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">Details</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-black-700 bg-white border-black">✕</button>
        </div>
        <div className="space-y-4">
          <div>
            <h4 className="font-bold text-gray-700">Customer Address:</h4>
            <p className="mt-1 text-gray-600">{customerAddress || 'No address provided'}</p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Store Drop:</h4>
            <p className="mt-1 text-gray-600">{storeDrop ? 'Yes' : 'No'}</p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Payment Method:</h4>
            <p className="mt-1 text-gray-600">{paymentMethod || 'Not specified'}</p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Phone Numbers:</h4>
            <p className="mt-1 text-gray-600">
              Primary: {customerPhone || 'Not provided'}<br/>
              Alternate: {alternatePhoneNo || 'Not provided'}
            </p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Desired Pickup Date:</h4>
            <p className="mt-1 text-gray-600">{formattedReviewDate}</p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Customer Comment:</h4>
            <p className="mt-1 text-gray-600">{customerComment || 'No customer comment'}</p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Review Comment:</h4>
            <p className="mt-1 text-gray-600">{reviewComment || 'No review comment'}</p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Repair Comments:</h4>
            <p className="mt-1 text-gray-600">{itemRepairComments || 'No repair comments'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};


export function UpdateInventory() {
  const [data, setData] = useState<UpdateInventoryItem[]>([]);
  const [confirmedItems, setConfirmedItems] = useState<Set<number>>(new Set()); // Track confirmed items
  const [serialNoErrors, setSerialNoErrors] = useState<Map<number, string>>(new Map()); // Track serial number errors
  const [selectedComment, setSelectedComment] = useState<{
    customerComment: string;
    reviewComment: string;
    itemRepairComments: string;
    customerAddress: string;
    storeDrop: boolean;
    paymentMethod: string;
    customerPhone: string;
    reviewDate: string;
    alternatePhoneNo: string;
  } | null>(null);

  useEffect(() => {
    const fetchInventoryItems = async () => {
      try {
        const [itemsResponse, reviewsResponse] = await Promise.all([
          getBuybackItems(),
          getBuybackReviews(),
        ]);

        const reviewsMap = new Map(
          reviewsResponse.data.map((review) => [
            review.item_id,
            {
              status: review.cs_review_status,
              customerComments: review.customer_comments || "",
              reviewComment: review.review_comments || "",
              customerAddress: review.customer_address || "",
              storeDrop: review.store_drop,
              reviewDate: review.cs_review_date || "",
            }
          ])
        );

        const inventoryData: UpdateInventoryItem[] = itemsResponse.data
          .filter((item) => item.payment_status === "PAID" && item.buyback_status === "APPROVED")
          .map((item) => {
            const review = reviewsMap.get(item.id);
            return {
              id: item.id,
              orderId: `0000${item.order_id}`,
              productName: item.product_name,
              customerName: `${item.customer_firstname} ${item.customer_lastname}`,
              customerComments: review?.customerComments || "",
              csDecision: review?.status || "PENDING",
              inventoryType: item.inventory_type,
              poid: item.po_id,
              poNumber: item.po_no,
              serialNo: "",
              productSKU: item.product_sku,
              reviewComment: review?.reviewComment || "",
              itemRepairComments: item.repair_comments || "",
              customerAddress: review?.customerAddress || "",
              storeDrop: review?.storeDrop || false,
              paymentMethod: item.payment_method || "",
              customerPhone: item.customer_phone || "",
              reviewDate: review?.reviewDate || "",
              alternatePhoneNo: item.alternate_phone_no || "",
            };
          });

        setData(inventoryData);
      } catch (error) {
        console.error("Error fetching inventory items:", error);
      }
    };

    fetchInventoryItems();
  }, []);

  const handleSerialNoChange = (index: number, value: string) => {
    setData((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, serialNo: value } : item
      )
    );
    if (value === "") {
      setSerialNoErrors((prev) => new Map(prev).set(index, "Please enter the serial number"));
    } else {
      setSerialNoErrors((prev) => new Map(prev).set(index, ""));
    }
  };

  const onConfirm = async (id: number, serialNo: string, index: number) => {
    if (serialNo === "" && data[index].inventoryType === "RENTAL_PRODUCT") {
      setSerialNoErrors((prev) => new Map(prev).set(index, "Please enter the serial number"));
      return; // Don't proceed with the update if serial no is empty
    }

    try {
      await updateInventory(id, {
        serialNumber: serialNo,
        uploadStatus: "UPLOADED",
        status: "available",
        notes: "",
        dateAcquired: "",
        purchasePrice:"",
      });
      setConfirmedItems((prev) => new Set(prev.add(id))); // Mark item as confirmed
      alert(`Item has been updated in the inventory`);
      console.log("Update successful");
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  return (
    <div className="rounded-lg bg-white p-6 shadow-lg">
      <h2 className="mb-6 text-center text-2xl font-semibold text-[#f78a8a]">
        Update Inventory
      </h2>
      <table className="w-full">
        <thead>
          <tr className="bg-[#f78a8a] text-white">
            <th className="py-2 px-4 text-left">ORDER ID</th>
            <th className="py-2 px-4 text-left">PRODUCT NAME</th>
            <th className="py-2 px-4 text-left">CUSTOMER NAME</th>
            <th className="py-2 px-4 text-left">CS DECISION</th>
            <th className="py-2 px-4 text-left">PRODUCT SKU</th>
            <th className="py-2 px-4 text-left">PO ID</th>
            <th className="py-2 px-4 text-left">SERIAL NO</th>
            <th className="py-2 px-4 text-left">INVENTORY TYPE</th>
            <th className="py-2 px-4 text-left">DETAILS</th>
            <th className="py-2 px-4 text-left">ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.id} className="border-b">
              <td className="py-2 px-4">{item.orderId}</td>
              <td className="py-2 px-4">{item.productName}</td>
              <td className="py-2 px-4">{item.customerName}</td>
              <td className="py-2 px-4">{item.csDecision}</td>
              <td className="py-2 px-4">
                <input
                  type="text"
                  placeholder="Enter SKU"
                  className="w-full p-2 border rounded bg-white"
                  value={item.productSKU}
                  readOnly
                />
              </td>
              <td className="py-2 px-4">{item.poNumber}</td>
              <td className="py-2 px-4">
                {item.inventoryType === "RENTAL_PRODUCT" ? (
                  <>
                    <input
                      type="number"
                      value={item.serialNo}
                      onChange={(e) => handleSerialNoChange(index, e.target.value)}
                      className="w-full rounded-md border bg-white border-gray-300 p-1"
                      placeholder="Enter Serial No"
                    />
                    {serialNoErrors.get(index) && (
                      <p className="text-red-500 text-sm mt-1">{serialNoErrors.get(index)}</p>
                    )}
                  </>
                ) : (
                  "NA"
                )}
              </td>
              <td className="py-2 px-4">{item.inventoryType}</td>
              <td className="py-2 px-4">
                <button
                  className="text-blue-600 hover:text-blue-800 underline bg-white"
                  onClick={() => setSelectedComment({
                    customerComment: item.customerComments,
                    reviewComment: item.reviewComment,
                    itemRepairComments: item.itemRepairComments,
                    customerAddress: item.customerAddress || '',
                    storeDrop: item.storeDrop,
                    paymentMethod: item.paymentMethod,
                    customerPhone: item.customerPhone || '',
                    reviewDate: item.reviewDate || '',
                    alternatePhoneNo: item.alternatePhoneNo || ''
                  })}
                >
                  View
                </button>
              </td>
              <td className="py-2 px-4">
                <button
                  onClick={() => onConfirm(item.id, item.serialNo, index)}
                  className={`px-4 py-2 rounded-full ${confirmedItems.has(item.id) ? "bg-gray-300 text-gray-500 cursor-not-allowed" : "bg-green-500 text-white"}`}
                  disabled={confirmedItems.has(item.id)} // Disable button after confirmation
                >
                  {confirmedItems.has(item.id) ? "Confirmed" : "Confirm"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedComment && (
        <CommentPopup
          customerComment={selectedComment.customerComment}
          reviewComment={selectedComment.reviewComment}
          itemRepairComments={selectedComment.itemRepairComments}
          customerAddress={selectedComment.customerAddress}
          storeDrop={selectedComment.storeDrop}
          paymentMethod={selectedComment.paymentMethod}
          customerPhone={selectedComment.customerPhone}
          reviewDate={selectedComment.reviewDate}
          alternatePhoneNo={selectedComment.alternatePhoneNo}
          onClose={() => setSelectedComment(null)}
        />
      )}
    </div>
  );
}
