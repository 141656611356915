import { useState, useEffect } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';

export interface AuthSession {
  isAuthenticated: boolean;
  isLoading: boolean;
  userRoles: string[];
}

export const useAuth = (): AuthSession => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setUserRoles] = useState<string[]>([]);

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    setIsLoading(true);
    try {
      const session = await fetchAuthSession();
      const roles = session.tokens?.accessToken?.payload['cognito:groups'] || [];
      setUserRoles(roles as string[]);
      setIsAuthenticated(session.tokens !== undefined);
    } catch (error) {
      console.error('Error checking auth status:', error);
      setIsAuthenticated(false);
      setUserRoles([]);
    } finally {
      setIsLoading(false);
    }
  };

  return { isAuthenticated, isLoading, userRoles };
}; 