import { useLocation } from "react-router-dom";
import Form from "../components/BuybackForm/Form";

const BuybackFormPage = () => {
  const location = useLocation();
  
  // Extract data from the URL parameters
  const queryParams = new URLSearchParams(location.search);
  const itemId = Number(location.pathname.split("/").pop());
  const productName = queryParams.get("productName") || "";
  const purchaseDate = new Date(queryParams.get("purchaseDate") || "");

  console.log("Form Data:", {
    itemId,
    productName,
    purchaseDate,
  });

  return (
    <div className="p-4 md:p-8 bg-gray-50 min-h-screen">
      <Form/>
    </div>
  );
};

export default BuybackFormPage;
