import React, { useState, FormEvent, ChangeEvent, useEffect } from 'react';
import { User } from '../market/types/types';

import { signIn, SignInInput, signUp, confirmSignIn } from 'aws-amplify/auth';
import { useAuth } from './hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';

interface AuthComponentProps {
  children: React.ReactNode;
}

const AuthComponent: React.FC<AuthComponentProps> = ({ children }) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [otp, setOtp] = useState<string>('');
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string>('');
  const [isSignUp, setIsSignUp] = useState<boolean>(false);
  const { isAuthenticated, isLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const generateComplexPassword = () => {
    const minLength = 8;
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const numberChars = '0123456789';
    const specialChars = '!@#$%^&*()_+{}[]|:;<>,.?~';
    let result = '';

    // Ensure at least one of each required character type
    result += uppercaseChars.charAt(Math.floor(Math.random() * uppercaseChars.length));
    result += lowercaseChars.charAt(Math.floor(Math.random() * lowercaseChars.length));
    result += numberChars.charAt(Math.floor(Math.random() * numberChars.length));
    result += specialChars.charAt(Math.floor(Math.random() * specialChars.length));

    // Fill the rest with random characters to reach the minimum length
    const allChars = uppercaseChars + lowercaseChars + numberChars + specialChars;
    for (let i = result.length; i < minLength; i++) {
      result += allChars.charAt(Math.floor(Math.random() * allChars.length));
    }

    // Shuffle the result
    return result.split('').sort(() => 0.5 - Math.random()).join('');
  };

  const cognitoSignup = async () => {
    const response = await signUp({
      username: phoneNumber,
      password: generateComplexPassword(),
      options: {
        userAttributes: {
          phone_number: phoneNumber,
          email: email,
          given_name: firstName,
          family_name: lastName,
        },
      },
    });
    console.log('response', response);
  };

  const cognitoSignin = async () => {
    const signInOptions: SignInInput = {
      username: phoneNumber,
      options: {
        authFlowType: 'CUSTOM_WITHOUT_SRP',
      },
    };

    const result = await signIn(signInOptions);
    console.log('result', result);

    if (result.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
      const user = {
        id: result.nextStep.additionalInfo?.USERNAME as string,
        phone: phoneNumber,
        verified: false,
      } as User;
      setUser(user);
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');

    try {
      if (isSignUp) {
        await cognitoSignup();
      }
      await cognitoSignin();
    } catch (err) {
      console.log('err', err);
      if (err instanceof Error && err.name === 'UserLambdaValidationException') {
        setIsSignUp(true);
      } else {
        setError(err instanceof Error ? err.message : 'An error occurred');
      }
    }
  };

  const handleOtpSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const result = await confirmSignIn({ challengeResponse: otp });
      console.log('result', result);
      if (result.isSignedIn) {
        console.log('Authentication successful');
        // Wait a brief moment for the auth state to update
        setTimeout(() => {
          const from = location.state?.from?.pathname || '/';
          navigate(from, { replace: true });
        }, 100);
      } else {
        console.log('Authentication failed');
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred during OTP verification');
    }
  };

  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  };

  const handleOtpChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  // If we're already authenticated and there's a redirect path, use it
  useEffect(() => {
    if (isAuthenticated && location.state?.from) {
      navigate(location.state.from.pathname, { replace: true });
    }
  }, [isAuthenticated, location.state, navigate]);

  if (isLoading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (isAuthenticated) {
    return <>{children}</>;
  }

  return (
    <div className="flex w-full justify-center items-center">
      <div className="bg-white p-16 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-600">
          {!user ? (isSignUp ? 'Sign Up' : 'Sign In') : 'Verify OTP'}
        </h2>
        {!user ? (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex flex-col items-start">
              <label htmlFor="phoneNumber" className="text-sm font-medium text-gray-700">Phone Number</label>
              <input
                id="phoneNumber"
                type="tel"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder="Enter phone number"
                required
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            {isSignUp && (
              <>
                <div className="flex flex-col items-start">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                  <input
                    id="email"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Enter email"
                    required
                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
                <div className="flex flex-col items-start">
                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
                  <input
                    id="firstName"
                    type="text"
                    value={firstName}
                    onChange={handleFirstNameChange}
                    placeholder="Enter first name"
                    required
                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
                <div className="flex flex-col items-start">
                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
                  <input
                    id="lastName"
                    type="text"
                    value={lastName}
                    onChange={handleLastNameChange}
                    placeholder="Enter last name"
                    required
                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
                  />
                </div>
              </>
            )}
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primaryhover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {isSignUp ? 'Sign Up' : 'Sign In'}
            </button>
          </form>
        ) : (
          <form onSubmit={handleOtpSubmit} className="space-y-4">
            <div className="flex flex-col items-start">
              <label htmlFor="otp" className="block text-sm font-medium text-gray-700">OTP</label>
              <input
                id="otp"
                type="text"
                value={otp}
                onChange={handleOtpChange}
                placeholder="Enter OTP"
                required
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
              />
            </div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primaryhover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              Verify OTP
            </button>
          </form>
        )}
        {error && <p className="mt-4 text-sm text-red-600">{error}</p>}
      </div>
    </div>
  );
};

export default AuthComponent;
