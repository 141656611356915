import React, { useEffect, useState } from "react";
import {
  getBuybackReviews,
  getBuybackItems,
  updateProductInspection,
} from "../api/AdminApi";
import { BuybackReviewTable } from "../types/types";

interface ProductInspectionItem {
  orderId: string;
  productName: string;
  customerName: string;
  customerComments: string;
  csDecision: string;
  productSKU: string;
  id: number;
  reviewId: number;
  inspectionStatus: "PENDING" | "ACCEPTED" | "REJECTED";
  repairComments?: string;
  itemRepairComments?: string;
  productCondition?: "IN_GOOD_SHAPE" | "REQUIRE_REPAIR";
  customerAddress: string;
  storeDrop: boolean;
  paymentMethod: string;
  customerPhone: string;
  reviewDate: string;
  alternatePhoneNo: string;
}

interface CommentPopupProps {
  customerComment: string;
  reviewComment: string;
  itemRepairComments: string;
  customerAddress: string;
  storeDrop: boolean;
  paymentMethod: string;
  customerPhone: string;
  reviewDate: string;
  alternatePhoneNo: string;
  onClose: () => void;
}

const CommentPopup: React.FC<CommentPopupProps> = ({ 
  customerComment, 
  reviewComment, 
  itemRepairComments,
  customerAddress,
  storeDrop,
  paymentMethod,
  customerPhone,
  reviewDate,
  alternatePhoneNo,
  onClose 
}) => {
  // Format reviewDate to 'YYYY-MM-DD'
  const formattedReviewDate = reviewDate
    ? new Date(reviewDate).toISOString().split("T")[0]
    : "Not specified";

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-2xl w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">Details</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-black-700 bg-white border-black">✕</button>
        </div>
        <div className="space-y-4">
          <div>
            <h4 className="font-bold text-gray-700">Customer Address:</h4>
            <p className="mt-1 text-gray-600">{customerAddress || 'No address provided'}</p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Store Drop:</h4>
            <p className="mt-1 text-gray-600">{storeDrop ? 'Yes' : 'No'}</p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Payment Method:</h4>
            <p className="mt-1 text-gray-600">{paymentMethod || 'Not specified'}</p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Phone Numbers:</h4>
            <p className="mt-1 text-gray-600">
              Primary: {customerPhone || 'Not provided'}<br/>
              Alternate: {alternatePhoneNo || 'Not provided'}
            </p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Desired Pickup Date:</h4>
            <p className="mt-1 text-gray-600">{formattedReviewDate}</p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Customer Comment:</h4>
            <p className="mt-1 text-gray-600">{customerComment || 'No customer comment'}</p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Review Comment:</h4>
            <p className="mt-1 text-gray-600">{reviewComment || 'No review comment'}</p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Repair Comments:</h4>
            <p className="mt-1 text-gray-600">{itemRepairComments || 'No repair comments'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export function ProductInspection() {
  const [data, setData] = useState<ProductInspectionItem[]>([]);
  const [repairComments, setRepairComments] = useState<{
    [key: number]: string;
  }>({});
  const [selectedCondition, setSelectedCondition] = useState<{
    [key: number]: string;
  }>({});
  const [errorMessages, setErrorMessages] = useState<{ [key: number]: string }>(
    {}
  );
  const [selectedComment, setSelectedComment] = useState<{
    customerComment: string;
    reviewComment: string;
    itemRepairComments: string;
    customerAddress: string;
    storeDrop: boolean;
    paymentMethod: string;
    customerPhone: string;
    reviewDate: string;
    alternatePhoneNo: string;
  } | null>(null);

  useEffect(() => {
    const fetchBuybackData = async () => {
      try {
        const [reviewsResponse, itemsResponse] = await Promise.all([
          getBuybackReviews(),
          getBuybackItems()
        ]);

        const transformedData: ProductInspectionItem[] = reviewsResponse.data
          .filter((item: BuybackReviewTable) =>
            item.cs_review_status === "ACCEPTED" ||
            item.cs_review_status === "CONDITIONALLY_ACCEPTED"
          )
          .map((item: BuybackReviewTable) => {
            const matchingItem = itemsResponse.data.find(
              (buybackItem) => buybackItem.id === item.item_id
            );

            return {
              productName: item.product_name,
              customerName: `${item.customer_firstname} ${item.customer_lastname}`,
              csDecision: item.cs_review_status,
              productSKU: item.product_sku,
              id: item.item_id,
              orderId: `0000${item.order_id}`,
              reviewId: item.id,
              inspectionStatus: item.inspection_status || "PENDING",
              repairComments: item.review_comments || "",
              customerComments: item.customer_comments,
              itemRepairComments: matchingItem?.repair_comments || "",
              productCondition: item.product_condition,
              customerAddress: item.customer_address,
              storeDrop: item.store_drop,
              paymentMethod: matchingItem?.payment_method || '',
              customerPhone: matchingItem?.customer_phone || '',
              reviewDate: item.cs_review_date,
              alternatePhoneNo: matchingItem?.alternate_phone_no || '',
            };
          });

        setData(transformedData);

        const initialCondition: { [key: number]: string } = {};

        transformedData.forEach((item, index) => {
          if (item.productCondition) {
            initialCondition[index] = item.productCondition;
          }
        });

        setSelectedCondition(initialCondition);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBuybackData();
  }, []);
  const handleConditionChange = (
    index: number,
    value: "IN_GOOD_SHAPE" | "REQUIRE_REPAIR"
  ) => {
    setSelectedCondition((prevState) => ({
      ...prevState,
      [index]: value,
    }));
    setErrorMessages((prevErrors) => ({ ...prevErrors, [index]: "" }));
  };

  const handleRepairCommentChange = (index: number, value: string) => {
    setRepairComments((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  };

  const handleConfirmInspection = async (
    item: ProductInspectionItem,
    index: number
  ) => {
    if (!selectedCondition[index]) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [index]: "Please select a Condition.",
      }));
      return;
    }

    try {
      await updateProductInspection(item.id, item.reviewId, {
        inspectionStatus: "ACCEPTED",
        repairComments: repairComments[index] || "",
        customerComments: item.customerComments,
        csReviewStatus: item.csDecision,
        csReviewerId: 1,
        productCondition: selectedCondition[index] as
          | "IN_GOOD_SHAPE"
          | "REQUIRE_REPAIR",
        paymentStatus: "PENDING"
      });

      setData((prevData) =>
        prevData.map((dataItem) =>
          dataItem.id === item.id
            ? { ...dataItem, inspectionStatus: "ACCEPTED" }
            : dataItem
        )
      );

      alert(`Inspection for ${item.productName} has been marked as COMPLETED.`);
    } catch (error) {
      console.error("Failed to update inspection status:", error);
      alert("Failed to update inspection status.");
    }
  };

  return (
    <div className="rounded-lg bg-white p-6 shadow-lg">
      <h2 className="mb-6 text-center text-2xl font-semibold text-[#f78a8a]">
        Product Inspection
      </h2>
      <table className="w-full">
        <thead>
          <tr className="bg-[#f78a8a] text-white">
          <th className="py-2 px-4 text-left">ORDER ID</th>
            <th className="py-2 px-4 text-left">PRODUCT NAME</th>
            <th className="py-2 px-4 text-left">CUSTOMER NAME</th>    
            <th className="py-2 px-4 text-left">CS DECISION</th>
            <th className="py-2 px-4 text-left">CONDITION</th>
            <th className="py-2 px-4 text-left">DETAILS</th>
            <th className="py-2 px-4 text-left">ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <tr className="border-b">
                <td className="py-2 px-4">{item.orderId}</td>
                <td className="py-2 px-4">{item.productName}</td>
                <td className="py-2 px-4">{item.customerName}</td>
                <td className="py-2 px-4">{item.csDecision}</td>
                <td className="py-2 px-4">
                  <select
                    className="w-full p-2 border rounded bg-white"
                    value={selectedCondition[index] || ""}
                    onChange={(e) =>
                      handleConditionChange(
                        index,
                        e.target.value as "IN_GOOD_SHAPE" | "REQUIRE_REPAIR"
                      )
                    }
                  >
                    <option value="">Select condition</option>
                    <option value="IN_GOOD_SHAPE">In Good Shape</option>
                    <option value="REQUIRE_REPAIR">Require Repair</option>
                  </select>
                  {!selectedCondition[index] && (
                    <p className="text-red-500 text-sm mt-1">
                      {errorMessages[index]}
                    </p>
                  )}
                </td>
                <td className="py-2 px-4">
                  <button
                    className="text-blue-600 hover:text-blue-800 underline bg-white"
                    onClick={() => setSelectedComment({
                      customerComment: item.customerComments,
                      reviewComment: item.repairComments || '',
                      itemRepairComments: item.itemRepairComments || '',
                      customerAddress: item.customerAddress,
                      storeDrop: item.storeDrop,
                      paymentMethod: item.paymentMethod,
                      customerPhone: item.customerPhone,
                      reviewDate: item.reviewDate,
                      alternatePhoneNo: item.alternatePhoneNo
                    })}
                  >
                    View
                  </button>
                </td>
                <td className="py-2 px-4">
                  <button
                    className={`px-4 py-2 rounded-full ${
                      item.inspectionStatus === "ACCEPTED"
                        ? "bg-gray-300 text-black"
                        : "bg-green-500 text-white"
                    }`}
                    onClick={() => handleConfirmInspection(item, index)}
                    disabled={item.inspectionStatus === "ACCEPTED"}
                  >
                    {item.inspectionStatus === "ACCEPTED"
                      ? "Completed"
                      : "Confirm"}
                  </button>
                </td>
               
              </tr>
              {selectedCondition[index] === "REQUIRE_REPAIR" && (
                <tr className="border-b">
                  <td colSpan={8} className="py-2 px-4">
                    <textarea
                      className="w-full p-2 border rounded bg-white"
                      placeholder="Add repair comments"
                      value={repairComments[index] || ""}
                      onChange={(e) =>
                        handleRepairCommentChange(index, e.target.value)
                      }
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {selectedComment && (
        <CommentPopup
          customerComment={selectedComment.customerComment}
          reviewComment={selectedComment.reviewComment}
          itemRepairComments={selectedComment.itemRepairComments}
          customerAddress={selectedComment.customerAddress}
          storeDrop={selectedComment.storeDrop}
          paymentMethod={selectedComment.paymentMethod}
          customerPhone={selectedComment.customerPhone}
          reviewDate={selectedComment.reviewDate}
          alternatePhoneNo={selectedComment.alternatePhoneNo}
          onClose={() => setSelectedComment(null)}
        />
      )}
    </div>
  );
}
