import axios from "axios";
import {
  BuybackResponse,
  BuybackReview,
  BuybackReviewResponse,
} from "../types/types";

const API_BASE_URL =
  import.meta.env.VITE_API_BASE_URL || "http://localhost:8000";

const apiClient = axios.create({
  baseURL: API_BASE_URL,
});

// Function to fetch buyback items
const getBuybackItems = async (page: number = 1): Promise<BuybackResponse> => {
  try {
    const response = await apiClient.get<BuybackResponse>(`/admin/buyback`, {
      params: {
        page, // Pagination parameter, if needed
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching buyback items:", error);
    throw error;
  }
};

const searchBuybackByParam = async (searchParam: string): Promise<BuybackResponse> => {
  try{
    const response = await apiClient.get<BuybackResponse>(`/admin/buyback/search`, {
      params: {
        searchParam
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error search buyback items:", error);
    throw error;
  }
};

// Function to update a buyback item
const updateBuybackItem = async (id: number, data: object) => {
  try {
    const response = await apiClient.put(`/admin/buyback/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Error updating buyback item:", error);
    throw error;
  }
};

const updateInventory = async (id: number, data: object) => {
  try {
    const response = await apiClient.put(`/admin/buyback/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Error updating buyback item:", error);
    throw error;
  }
};

// Function to delete a buyback item
const deleteBuybackItem = async (id: number) => {
  try {
    const response = await apiClient.delete(`/admin/buyback/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting buyback item:", error);
    throw error;
  }
};

// Function to post a review for a buyback item
const postBuybackReview = async (
  id: number,
  reviewData: Omit<
    BuybackReview,
    "csReviewStatus" | "inspectionStatus" | "inspectionNotes"
  >
) => {
  try {
    const defaultData = {
      csReviewStatus: "PENDING",
      inspectionStatus: "PENDING",
      inspectionNotes: "Needs further inspection",
    };

    const response = await apiClient.post(`/admin/buyback/${id}/reviews`, {
      ...defaultData,
      ...reviewData, // Merge user-provided review data with default values
    });

    return response.data;
  } catch (error) {
    console.error("Error posting buyback review:", error);
    throw error;
  }
};

const getBuybackReviews = async (): Promise<BuybackReviewResponse> => {
  try {
    const response = await apiClient.get<BuybackReviewResponse>(
      "/admin/buyback/reviews"
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching buyback reviews:", error);
    throw error;
  }
};

// Function to update a review for a buyback item
const updateBuybackReview = async (
  itemId: number,
  reviewId: number,
  reviewData: Pick<BuybackReview, "csReviewStatus"> & { reviewComments?: string }
) => {
  try {
    // Construct the URL
    const url = `/admin/buyback/${itemId}/reviews/${reviewId}`;

    const response = await apiClient.put(url, reviewData);
    return response.data;
  } catch (error) {
    console.error("Error updating buyback review:", error);
    throw error;
  }
};


const updateProductInspection = async (
  itemId: number,
  reviewId: number,
  reviewData: Pick<BuybackReview, "inspectionStatus" > & {
    customerComments: string;
    csReviewStatus: string;
    csReviewerId: number;
    // inventorySku: string;
    repairComments: string | string[];
    productCondition: 'IN_GOOD_SHAPE' | 'REQUIRE_REPAIR';
    // inventoryType: "USED_PRODUCT" | "RENTAL_PRODUCT";
    paymentStatus: "PENDING" | "INITIATED" | "PAID" | "CANCELLED";
  }
) => {
  try {
    // Construct the URL
    const url = `/admin/buyback/${itemId}/reviews/${reviewId}`;

    const response = await apiClient.put(url, reviewData);
    return response.data;
  } catch (error) {
    console.error("Error updating buyback review:", error);
    throw error;
  }
};

// Function to update payment status and final price of a buyback item
const updateBuybackPaymentStatus = async (
  id: number,
  finalBuybackPrice: string,
  paymentStatus: 'PENDING' | 'INITIATED' | 'PAID' | 'CANCELLED',
  paymentMethod: 'CASH' | 'CREDIT_CARD' | 'BANK_TRANSFER' | 'OTHER',
  inventorySku?: string,
  inventoryType?: "USED_PRODUCT" | "RENTAL_PRODUCT",
  buybackStatus?: string
) => {
  try {
    // Construct payload to match the exact structure required by the API
    const data = {
      notes: "",
      dateAcquired: "",
      status: "available",
      paymentMethod,
      inventoryType,
      inventorySku,
      finalBuybackPrice,
      paymentStatus,
      buybackStatus
    };

    const response = await apiClient.put(`/admin/buyback/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Error updating buyback payment status:", error);
    throw error;
  }
};

const getBuybackReviewMedias = async (itemId: number, reviewId: number) => {
  try {
    const response = await apiClient.get(`/admin/buyback/${itemId}/reviews/${reviewId}/medias`);
    return response.data;
  } catch (error) {
    console.error("Error fetching buyback review medias:", error);
    throw error;
  }
};

// Function to upload media for a buyback review
const uploadBuybackReviewMedia = async (
  itemId: number,
  mediaFile: File,
  mediaType: 'IMAGE' | 'VIDEO' | 'DOCUMENT'
) => {
  try {
    const formData = new FormData();
    formData.append('mediaFile', mediaFile);
    formData.append('mediaType', mediaType);

    const response = await apiClient.post(
      `/admin/buyback/${itemId}/medias`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error uploading media:", error);
    throw error;
  }
};


export {
  getBuybackItems,
  searchBuybackByParam,
  updateBuybackItem,
  deleteBuybackItem,
  postBuybackReview,
  getBuybackReviews,
  updateBuybackReview,
  updateProductInspection,
  updateBuybackPaymentStatus,
  updateInventory,
  getBuybackReviewMedias, 
  uploadBuybackReviewMedia,
};
